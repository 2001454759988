import { useEffect } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import { request } from "../lib/datocms";
import { getAllProducts } from "../lib/shopify";
import Image from "next/image";
import { useRouter } from "next/router";
import WorkIndex from "../components/WorkIndex";
import { useInView } from "react-intersection-observer";
import { useStore } from "./_app";

const HOMEPAGE_QUERY = `query HomePage {
  home {
    title,
    heroImage {
      responsiveImage {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
    }
  },
}`;

export async function getStaticProps() {
  const homeData = await request({
    query: HOMEPAGE_QUERY,
  });
  const globalSettings = await request({
    query: `query GlobalSettings {
      global {
        pauseAllSales
        newDropDate
      },
    }`,
  });
  const productsData = await getAllProducts();
  return {
    props: { homeData, productsData, globalSettings },
    revalidate: 30,
  };
}

export default function Home({ homeData, productsData, globalSettings }) {
  const route = useRouter();
  const setLightUIScheme = useStore((state) => state.setLightUIScheme);
  const setDarkUIScheme = useStore((state) => state.setDarkUIScheme);
  const setHeaderTransparent = useStore((state) => state.setHeaderTransparent);
  const setHeaderOpaque = useStore((state) => state.setHeaderOpaque);

  const { ref, inView, entry } = useInView({
    threshold: 0.0,
  });

  useEffect(() => {
    if (inView) {
      setHeaderTransparent();
      setLightUIScheme();
      //route.replace("/", "/", { shallow: true });
    } else {
      setHeaderOpaque();
      setDarkUIScheme();
      //route.replace("/", "/work", { shallow: true });
    }
  }, [inView]);

  return (
    <Layout uiScheme="light">
      <HeroPanel>
        {/* <StyledImage homeData={homeData.home.heroImage.responsiveImage} /> */}
        <HeroLogo>Veikkanen</HeroLogo>
        <Image
          src={homeData.home.heroImage.responsiveImage.src}
          layout="fill"
          objectFit="cover"
        />
      </HeroPanel>
      <IntersectionSpace ref={ref} />
      <WorkIndex products={productsData} globalSettings={globalSettings} />
    </Layout>
  );
}

const HeroPanel = styled.div`
  width: 100%;
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 100;

  height: 100vh;
`;

const HeroLogo = styled.h1`
  position: relative;
  z-index: 500;
  color: var(--colorBrandCream);
  text-transform: lowercase;
  letter-spacing: -0.025em;

  font-weight: 400;

  font-size: var(--step-7);

  @media screen and (min-width: 768px) {
    font-size: var(--step-10);
  }
`;

const StyledImage = styled(Image)`
  height: 100%;
  outline: 3px dashed orange;
  position: relative;
  z-index: 300;
`;

const IntersectionSpace = styled.div`
  position: relative;
  z-index: 500;
  height: 95vh;
  pointer-events: none;
`;
